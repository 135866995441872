import React, { useMemo } from 'react';
import { session_user_uuid, session_parent_uuid } from '../session.js';
import CustomDatePicker from '../../Components/CustomDatePicker';
import { post_data, post_data_mws, get_page_content } from '../API_Calls.js';
import { createRoot } from 'react-dom/client';
import * as SF from '../ModuleFunctions/SSAS_Functions.js';
import { MaritraceUser } from './UserData';
import * as G from '../Other/General.js';
import { Badge } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

export const SSASFailedStatus = <Badge id='current_ssas_status' bg='dark'>UNKNOWN</Badge>;
export const scheduledTestStatuses = new Map([
    [0, ['NOT APPLICABLE', <Badge bg='secondary'>NOT APPLICABLE</Badge>]],
    [1, ['PENDING', <Badge bg='primary'>PENDING</Badge>]],
    [2, ['TEST UNDERWAY', <Badge bg='warning'>TEST UNDERWAY</Badge>]],
    [3, ['SUCCESSFUL', <Badge bg='success'>SUCCESSFUL</Badge>]],
    [4, ['UNSUCCESSFUL', <Badge bg='danger'>UNSUCCESSFUL</Badge>]]
]);
export const alertStatusElement = new Map([
    [0, ['SSAS FIRING IN TEST MODE', <Badge id='current_ssas_status' bg='warning'>SSAS FIRING IN TEST MODE</Badge>]],
    [1, ['SSAS FIRING', <Badge id='current_ssas_status' bg='danger'>SSAS FIRING</Badge>]],
    [2, ['SSAS ENABLED', <Badge id='current_ssas_status' bg='success'>SSAS ENABLED</Badge>]],
    [3, ['SSAS ACTIVATION REQUESTED', <Badge id='current_ssas_status' bg='secondary'>SSAS ACTIVATION REQUESTED</Badge>]],
    [4, ['SSAS IN TEST MODE', <Badge id='current_ssas_status' bg='primary'>SSAS IN TEST MODE</Badge>]],
    [5, ['NOT ENABLED', <Badge id='current_ssas_status' bg='light'>NOT ENABLED</Badge>]]
]);
export const logStatusElement = new Map([
    [0, ['NOT APPLICABLE', <Badge bg='secondary'>NOT APPLICABLE</Badge>]],
    [1, ['LIVE', <Badge bg='danger'>LIVE</Badge>]],
    [2, ['TEST', <Badge bg='warning'>TEST</Badge>]]
]);
export const ssasLogTypes = new Map([
    [0, ["517d7d44-dc48-47ac-af10-6b50f0c9996a", "Activation Requested"]],
    [1, ["a58a0a33-e751-498e-aaf4-788cfe02c189", "Activation Approved"]],
    [2, ["7b66b77f-7c1a-47eb-80bb-3077bc7f136d", "Alert Message Received"]],
    [3, ["bc5f17e2-292c-4813-a0d8-24b7bdb51dd1", "Email Sent"]],
    [4, ["675a6cd6-c7be-46f5-8d99-1bc0fb4346d1", "Notification Sent"]],
    [5, ["6d3fd8fd-150d-40f8-be8a-238ae4473036", "Notification Acknowledged"]],
    [6, ["5cb940ae-779b-45c6-a14e-aea8ce0ba526", "Alert Muted"]],
    [7, ["70cc8fd1-1064-46d3-b530-6d76b1d74941", "Alert Unmuted"]],
    [8, ["27d01bea-f04e-4d63-b80d-46c7378e3a0a", "Alert Cancelled"]],
    [9, ["a2c3965c-40f2-45d7-9412-d41efa52bda4", "Test Mode Activated"]],
    [10, ["e089493f-790d-4263-8efa-8ce1857e695d", "Test Mode Deactivated"]],
    [11, ["7e598541-cc5f-4fa2-a612-d137f67748d9", "Test Mode Outcome"]],
    [12, ["ee0bbbf7-05ba-40c8-8cb2-4feb9f019f21", "Contact Log"]],
    [13, ["bfe34e82-a34a-46c1-8776-2428031bff0e", "Other Log"]],
    [14, ["d5947ff9-f6d1-401f-a32f-2691da2e42e6", "Deactivation Requested"]],
    [15, ["207adacf-8d31-4c52-9250-7cb8d1c9fbb7", "Deactivation Approved"]],
    [16, ["3ab08261-9351-4b64-a41e-2690504b04d8", "Test Muted"]],
    [17, ["db2f9f08-cee0-48d1-b5ee-f81afff7703d", "Activation Cancelled"]],
    [18, ["ecfb6c72-7112-4988-b8fe-59f3285b7c7d", "Test Scheduled"]],
    [19, ["1724df22-aa8c-46b2-96d1-0e862ff654d3", "Vessel Status Change"]],
    [20, ['3a0a5918-cd27-4b76-bb0e-3396bc8c452c', 'Test Unmute']],
]);

export type ssasContent = {
  ssas_dashboard_tab_title: string;
  ssas_dashboard_tab_table1_title: string;
  ssas_dashboard_tab_table1_column: string;
  ssas_dashboard_tab_table2_title: string;
  ssas_dashboard_tab_table2_column: string;
  ssas_schedule_tab_title: string;
  ssas_schedule_tab_table_column: string;
  ssas_contacts_tab_title: string;
  ssas_contacts_tab_table_column: string;
  ssas_event_tab_title: string;
  ssas_event_tab_table_column: string;
  ssas_vessel_tab_title: string;
  ssas_vessel_tab_table_column: string;
}

export let defaultSsasContent: ssasContent = {
  ssas_dashboard_tab_title: "",
  ssas_dashboard_tab_table1_title: "",
  ssas_dashboard_tab_table1_column: "",
  ssas_dashboard_tab_table2_title: "",
  ssas_dashboard_tab_table2_column: "",
  ssas_schedule_tab_title: "",
  ssas_schedule_tab_table_column: "",
  ssas_contacts_tab_title: "",
  ssas_contacts_tab_table_column: "",
  ssas_event_tab_title: "",
  ssas_event_tab_table_column: "",
  ssas_vessel_tab_title: "",
  ssas_vessel_tab_table_column: "",
};

export async function fetchSSASContent(){
    const page_content = await get_page_content("ssas");
    defaultSsasContent = page_content;
    return defaultSsasContent;
}
export interface SSASUser {
  user_uuid: string;
  user_name: string;
}
export let defaultSsasUser: ssasUser= {
  user_uuid: null,
  user_name: null
}
export interface SSASAlert {
  alert_uuid: string;
  start_date: Date;
  end_date: Date | null;
  ssas_vessel_uuid: string;
  vessel_info: SSASVessel;
}

export async function getAlertsData(get_tests=false, alert_uuids = []){
    let res_data: SSASAlert[];
    if(get_tests){
        const parameters = {
            "alert_uuid" : alert_uuids,
            "parent_uuid" : session_parent_uuid,
        }
        const res = await post_data("/getSSASTestAlert_List", parameters);
        res_data = res["ssasTestAlerts_list"];
    }
    else{
        const parameters = {
            "alert_uuid" : alert_uuids,
            "parent_uuid" : session_parent_uuid,
        }

        const res = await post_data("/getSSASAlert_List", parameters);
        res_data = res["ssasAlerts_list"];
    }
    return res_data;
}

export async function getAlertsArchiveData(alert_uuids, vessel_uuids){
    const parameters = {
        "parent_uuid" : session_parent_uuid,
    }

    const res = await post_data("/getSSASAlertArchive_List", parameters);
    let res_data: SSASAlert[] = res["SSASAlerts_list"];
    return res_data;
}

export interface SSASVessel {
  ssas_vessel_uuid: string;
  tracked_vessel_uuid: string;
  user_uuid: string;
  parent_uuid: string;
  current_status: string;
  email_address: string;
  vessel_email: string;
  vessel_name: string;
  vessel_imo: string;
  vessel_mmsi: string;
  geopoint: string;
  lat: Double | null;
  lon: Double | null;
  //LOCAL
  next_test_date: Date | null;
}

export let defaultSsasVessel: SSASVessel = {
  ssas_vessel_uuid: "",
  tracked_vessel_uuid: "",
  user_uuid: "",
  parent_uuid: "",
  current_status: "",
  email_address: "",
  vessel_name: "",
  vessel_imo: "",
  vessel_mmsi: "",
  geopoint: "",
  lat: null,
  lon: null,
  next_test_date: null
};

export async function getSSASVesselData(ssas_vessel_uuids, tracked_vessel_uuids, status){
     const parameters = {
        "sass_vessel_uuid" : ssas_vessel_uuids,
        "tracked_vessel_uuid" : tracked_vessel_uuids,
        "status": status,
        "parent_uuid" : session_parent_uuid
    }
    const res = await post_data("/getSSASVessel_List", parameters);
    let res_data: SSASVessel[] = res["ssasVessel_list"];
    return res_data;
}

export async function getSSASVessel_nextScheduledTests(ssas_vesselList){
    let ssas_vessel_uuids = ssas_vesselList.map(item => item.ssas_vessel_uuid);
    let scheduledTests = await getSSASScheduledTests([], ssas_vessel_uuids, "", false, false, false);
    const earliestScheduledTests = scheduledTests.reduce((acc, test) => {
        const uuid = test.ssas_vessel_uuid;
        const isEarlier = !acc[uuid] || G.setUTCDate(test.schedule_date) < G.setUTCDate(acc[uuid].schedule_date);
        if (isEarlier) acc[uuid] = test;
        return acc;
    }, {});

    ssas_vesselList.forEach((item) => {
        if (earliestScheduledTests[item.ssas_vessel_uuid]) {
            item.next_test_date = earliestScheduledTests[item.ssas_vessel_uuid].schedule_date;
        }
    });
    return ssas_vesselList;
}

export interface SSASScheduledTests {
    schedule_uuid: string;
    ssas_vessel_uuid: string;
    user_uuid: string;
    parent_uuid: string;
    current_status: string;
    schedule_date: Date | null;
    schedule_by: SSASUser | null;
    test_active: bool;
    started_date: Date | null;
    started_by: SSASUser | null;
    completed_date: Date | null;
    completed_by: SSASUser;
    deleted: Date | null;
    deleted_by: SSASUser | null;
    // LOCAL
    vessel_info: SSASVessel | null;
    expand: bool;
}

export let defaultSsasScheduledTests: SSASScheduledTests = {
    schedule_uuid: "",
    ssas_vessel_uuid: "",
    user_uuid: "",
    parent_uuid: "",
    current_status: "",
    schedule_date: null,
    schedule_by: "",
    test_active: false,
    started_date: null,
    started_by: null,
    completed_date: null,
    completed_by: null,
    deleted: null,
    deleted_by: null,
    vessel_info: null,
    expand: false
}

export async function getSSASScheduledTests(schedule_uuid, ssas_vessel_uuids, status, include_completed,
        include_deleted, within_24, byschedule_date = ""){
    let byDate = null
    if(byschedule_date != "") byDate = G.setUTCDate(byschedule_date);
    const parameters = {
        "parent_uuid" : session_parent_uuid,
        "schedule_uuid" : schedule_uuid,
        "ssas_vessel_uuid" : ssas_vessel_uuids,
        "status" : status,
        "include_completed" : include_completed,
        "include_deleted" : include_deleted,
        "within_24" : within_24,
        "byschedule_date" : byDate
    }

    const res = await post_data("/getSSASScheduledTest_List", parameters);
    let res_data: SSASScheduledTests[] = res["ssasScheduledTest_list"];
    return res_data;
    /*if(res["ssasScheduledTest_list"] != null && res["ssasScheduledTest_list"].length > 0){
        let res_data: SSASScheduledTests[] = res["ssasScheduledTest_list"];
        return res_data;
    } else return null;*/
}

export interface SSASContact {
 archive_contact_uuid: string | null;
 contact_uuid: string;
 user_uuid: string;
 parent_uuid: string;
 user_name: string;
 user_company: string;
 user_role: string;
 user_email: string;
 user_phone: string;
 user_sms: string;
 active_email: bool;
 active_screen: bool;
 test_email: bool;
 test_screen: bool;
 internal_bool: bool;
 master: bool;
 /* Local */
 priority_level: int;
 expand: boolean;
 relation: SSASAffiliation[] | null;
}

export let defaultSsasContact: SSASContact = {
    archive_contact_uuid: null,
    contact_uuid: "",
    user_uuid: "",
    parent_uuid: "",
    user_name: "",
    user_company: "",
    user_role: "",
    user_email: "",
    user_phone: "",
    user_sms: "",
    active_email: false,
    active_screen: false,
    test_email: false,
    test_screen: false,
    internal_bool: false,
    master: false,
    /* Local */
    priority_level : 0,
    expand: false,
    relation: null
}

export async function getContactData(contact_uuids, vessel_uuids, byUserUuid = false, internal = false){
     let user_uuid = null;
     if(byUserUuid) user_uuid = session_user_uuid;

     const parameters = {
        "contact_uuid" : contact_uuids,
        "user_uuid" :  user_uuid,
        "parent_uuid" : session_parent_uuid,
        "include_deleted" : false,
        "internal_user" : internal
    }

    const res = await post_data("/getSSASContact_List", parameters);

    let res_data: SSASContact[] = res["ssasContact_list"].map(contact => ({
        ...contact,
        expand: false
    }));
    return res_data;
}

export interface SSASEvent {
 events_logs_uuid: string;
 alert_details: SSASAlert | null;
 ssas_vessel_details: SSASVessel | null;
 test_details: SSASScheduledTests | null;
 contact_details: SSASContact | null;
 log_date: Date | null;
 log_type: string;
 log_mode: string;
 log_details: string;
 completed: Date | null;
 completed_by: SSASUser | null;
}

export let defaultSsasEvent: SSASEvent = {
 events_logs_uuid: "",
 alert_details: null,
 vessel_details: null,
 test_details: null,
 contact_details: null,
 log_date: null,
 log_type: "",
 log_mode: "",
 log_details: "",
 completed: null,
 completed_by: defaultSsasUser,
}

export async function getEventData(event_uuids, alert_uuids, contact_uuids, tracked_vessel_uuids, ssas_vessel_uuids, scheduled_test_uuid, include_deleted){
    const parameters = {
        "events_logs_uuid" : event_uuids,
        "alert_uuid" : alert_uuids,
        "contact_uuid" : contact_uuids,
        "ssas_vessel_uuid" : ssas_vessel_uuids,
        "tracked_vessel_uuid" : tracked_vessel_uuids,
        "scheduled_test_uuid" : scheduled_test_uuid,
        "user_uuid" :  null,
        "parent_uuid" : session_parent_uuid,
        "include_deleted" : include_deleted,
    }

    const res_analysis = await post_data("/getSSASEventLog_List", parameters);
    let res_data: SSASEvent[] = res_analysis["ssasEventLog_list"];
    return res_data;
}

export interface SSASAffiliation {
    link_ssas_vessels_contacts_uuid: string;
    contact_uuid: string;
    ssas_vessel_uuid: string;
    priority_level: number;
    deleted: Date | null;
    deleted_by: SSASUser | null;
    active_email: bool;
    active_screen: bool;
    test_email: bool;
    test_screen: bool;
    mute_test_alarm: bool;
    mute_live_alarm: bool;
}

export async function getAffiliationData(priority_levels, contact_uuids, ssas_vessel_uuids){
    const parameters = {
       "priority_levels": priority_levels,
       "contact_uuid": contact_uuids,
       "ssas_vessel_uuid": ssas_vessel_uuids
    };
    const res = await post_data("/getSSASAffiliation_List", parameters);
    let res_data: SSASAffiliation[] = res["ssasAffiliation_list"];
    return res_data;
}

let scheduleTestVessel: SSASVessel;
let scheduleTestContact: SSASContact;
export function scheduleNewTest(ssasVessel, contact, containerId){
    scheduleTestVessel = ssasVessel;
    scheduleTestContact = contact;

    var parent = document.getElementById(containerId);

    let popup_overlay = document.createElement('div');
    popup_overlay.className = 'overlay';
    popup_overlay.id = 'ssas_schedule_test_date_popup';
    popup_overlay.onclick = function() {
        popup_overlay.remove();
    };

    let popup_content = document.createElement('div');
    popup_content.className = 'centered-content';
    popup_content.onclick = function(e) {
         e.stopPropagation();
     };
    let customDatePickerContainer = document.createElement('div');

    const root = createRoot(
        customDatePickerContainer
    );
    const today = G.setUTCDate();
    root.render(<CustomDatePicker
        breadcrumbs="SSASTab|DatePicker"
        startDate={null}
        endDate={null}
        minimumDate={today.toString()}
        maximumDate={null}
        range={false}
        include_time={true}
        call_back_func={(returnDate) => handleScheduleTestDatePicker(returnDate, "SSASTab|DatePicker")}
    />);

    popup_content.appendChild(customDatePickerContainer);
    popup_overlay.appendChild(popup_content);
    parent.appendChild(popup_overlay);
}

interface eventLogParameters {
    alert: SSASAlert | null;
    vessel: SSASVessel | null;
    scheduledTests: SSASScheduledTests | null;
    contact: SSASContact | null;
    log_type_number:number | null;
    log_mode_number:number | null;
    result_notes:string | null;
    completed_uuid:string | null;
}
export let defaultLogParameters: defaultLogParameters = {
    alert: null,
    vessel: null,
    scheduledTests: null,
    contact: null,
    log_type_number: null,
    log_mode_number: null,
    result_notes: null,
    completed_uuid: null
}

async function handleScheduleTestDatePicker(returnDate, breadcrumbs){
    if(scheduleTestVessel != null && scheduleTestContact != null){
        let parameters= {
            schedule_uuid: uuidv4(),
            ssas_vessel_uuid: scheduleTestVessel.ssas_vessel_uuid,
            user_uuid: session_user_uuid,
            parent_uuid: session_parent_uuid,
            current_status: "PENDING",
            schedule_date: returnDate.replace(" ", "T"),
            schedule_by: { user_name : "", user_uuid: scheduleTestContact.contact_uuid },
            test_active: false,
            started_date: null,
            started_by: null,
            completed_date: null,
            completed_by: null,
            deleted: null,
            deleted_by: null,
        }
        let result = await postSSASSchedule([parameters]);

        if(result) {

            let logParameters:eventLogParameters = {
                alert: null,
                vessel: null,
                scheduledTests: scheduleTestVessel,
                contact: scheduleTestContact,
                log_type_number: 18,
                log_mode_number: 2,
                result_notes: null,
                completed_uuid: null
            }

            if(scheduleTestVessel.vesselInfo?.ssas_vessel_uuid != ""){
                logParameters.vessel = scheduleTestVessel.vesselInfo;
            }
            else{
                const resultVessel = await getSSASVesselData([scheduleTestVessel.ssas_vessel_uuid], [], "");
                if(resultVessel.length > 0) logParameters.vessel = result[0];
            }

            await createPostSSASEventLogParam([logParameters]);
            window.location.reload();
        }
    }
}

function getLogTypeNumber(uuidOrName: string): number | null {
    for (const [number, value] of ssasLogTypes) {
        if (value[0] === uuidOrName || value[1] === uuidOrName) {
            return number;
        }
    }
    return null;
}

export async function updateSSASTracking(vessel_uuid, vessel_email, ssasVessel, ssasUser, action){
    let parameters: eventLogParameters = structuredClone(defaultLogParameters);
    let result = [];
    parameters.log_type_number = getLogTypeNumber(action);
    console.log("vessel_uuid api : ", vessel_uuid);
    if(ssasVessel == null) {
        result = await ActivateSSASTracking(vessel_uuid, vessel_email);
        if(result[0]){
            parameters.vessel = result[1][0];
            if(result[1] != null){
                parameters.contact = result[1][1];
                parameters.completed_uuid = parameters.contact.contact_uuid;
            }
            parameters.log_mode_number = logStatusElement.get(0)?.[0];
        }
    }

    await createPostSSASEventLogParam([parameters])
    return result[1];
}

export async function ActivateSSASTracking(vessel_uuid, vessel_email){
    const parameters = {
        "tracked_vessel_uuid" : vessel_uuid,
        "parent_account_uuid" : session_parent_uuid,
        //"vessel_uuid" :  null,
        "search_term" : "",
        "only_basic_info" : false,
        "is_deleted" : false,
    }

    const res = await post_data("/get_TrackedVessels", parameters);
    if(res === null && res["vessel"] === null && res["tracked_vessel_uuid"] === null && res["tracked_vessel_uuid"] === "" ){
        return false;
    }
    const trackedRes = res[0];

    const contactParameters = {
        "contact_uuid" : [],
        "user_uuid" :  session_user_uuid,
        "parent_uuid" : session_parent_uuid,
        "include_deleted" : false
    }
    const resContact = await post_data("/getSSASContact_List", contactParameters);

    let userContact: SSASContact = structuredClone(defaultSsasContact);
    let newVessel;
    let userContactList: SSASContact[] = resContact["ssasContact_list"];
    if(userContactList === null || userContactList.length === 0) userContact = await insertNewSSASContact();
    else userContact = userContactList[0];

    if(userContact != null){
        let newAffiliation;
        let newSSASVesselUuid = uuidv4();
        let tracked_imo = "";
        let tracked_mmsi = "";
        if(trackedRes["vessel"] != null && trackedRes["vessel"]["imo"] != null && trackedRes["vessel"]["imo"] != ""){
            tracked_imo = trackedRes["vessel"]["imo"];
        }
        else if(trackedRes["vessel"] === null && trackedRes["vessel"]["mmsi"] != null && trackedRes["vessel"]["mmsi"] != ""){
            tracked_mmsi = trackedRes["vessel"]["mmsi"];
        }
        else{
            console.error("ActivateTracking: Tracked vessel imo and mmsi are empty");
            return [null, userContact, null];
        }

        newVessel = await insertNewSSASVessel(newSSASVesselUuid, trackedRes["tracked_vessel_uuid"], tracked_imo, tracked_mmsi, vessel_email);
        if(newVessel != null){
            newAffiliation = await insertNewSSASAffiliation(userContact, newVessel.vessel_list[0], 0);
            if(newAffiliation != null){
                return [true, [newVessel.vessel_list[0], userContact, newAffiliation]];
            }
        }
        else{
            return [false, [newVessel.vessel_list[0], userContact, null]];
        }
    }
    else{
        return [false, [null, null, null]];
    }
}

export async function insertNewSSASContact(addContact = null, maritraceUser = null, log_note = ""){
    let parameters: eventLogParameters = structuredClone(defaultLogParameters);
    parameters.result_notes = log_note;
    parameters.log_type_number = 12;
    parameters.log_mode_number = 0;

    let newContact: SSASContact = structuredClone(defaultSsasContact);
    if(addContact){
        newContact = addContact;
    }
    else{
        let userMaritrace: MaritraceUser;
        if(maritraceUser) userMaritrace = maritraceUser;
        else {
            const maritrace_user_parameters = {
                "parent_account_uuids" :  [session_parent_uuid],
                "user_account_uuids" : [session_user_uuid],
            }

            const res_user = await post_data("/get_MariTraceUserAccount", maritrace_user_parameters);

            if(res_user.length <= 0){
                console.error("MariTrace user not found");
                return null;
            }
            else{
                userMaritrace = res_user[0];
            }
        }

        newContact.archive_contact_uuid = uuidv4();
        newContact.contact_uuid = uuidv4();
        newContact.user_uuid = userMaritrace.user_account_uuid;
        newContact.parent_uuid = userMaritrace.maritrace_parent_account.parent_account_uuid;
        newContact.user_name = ""+userMaritrace.first_name+" "+userMaritrace.last_name;
        newContact.user_company = userMaritrace.maritrace_parent_account.parent_account_name;
        newContact.user_email = userMaritrace.email;
        newContact.internal_bool = true;
    }

    newContact.user_uuid = newContact.user_uuid !== "" ? newContact.user_uuid : null;
    newContact.parent_uuid = newContact.parent_uuid !== "" ? newContact.parent_uuid : session_parent_uuid;
    const insertContact = {
        "contact_list" : [newContact]
    }
    const result = await upsertSSASContact(insertContact);

    parameters.contact = newContact;
    if(log_note === "") parameters.result_notes = "New Contact added: "+ newContact.user_name;
    if(result.is_successful){
        const creatingUser = await getContactData([], [], true);
        parameters.completed_uuid = creatingUser.contact_uuid;
        await createPostSSASEventLogParam([parameters]);
        return newContact;
    }
    else{
        return null;
    }
}

export async function upsertSSASContact(contactDetails){
    const res_insert_contact = await post_data_mws("/upsertSSASContact", contactDetails, 'SSAS_CONTACT');

    return res_insert_contact;
}

export async function insertNewSSASVessel(ssas_vessel_uuid, tracked_uuid, tracked_imo, tracked_mmsi, vessel_email){
    const newVessel = {
        "vessel_list" : [{
            "ssas_vessel_uuid" : ssas_vessel_uuid,
            "tracked_vessel_uuid" : tracked_uuid,
            "user_uuid" : session_user_uuid,
            "parent_uuid" : session_parent_uuid,
            "current_status" : "SSAS ENABLED",
            "vessel_imo" : tracked_imo,
            "vessel_mmsi" : tracked_mmsi,
            "vessel_email" : vessel_email
        }]
    }

    const result = await upsertSSASVessel(newVessel);
    if(result.is_successful){
        return newVessel;
    }
    else{
        return null;
    }
}

export async function upsertSSASVessel(vesselDetails){
    const resSSASVessel = await post_data("/upsertSSASVessel_List", vesselDetails);

    return resSSASVessel;
}

export async function insertNewSSASAffiliation(contact, ssas_vessel, priority){
    if(!contact || !contact.contact_uuid || G.isBlankUUID(contact.contact_uuid) ||
    !ssas_vessel || !ssas_vessel.ssas_vessel_uuid || G.isBlankUUID(ssas_vessel.ssas_vessel_uuid)) return null;
    console.log("testing");
    const newAffiliation = {
        "SSASAffiliation_list": [{
            "link_ssas_vessels_contacts_uuid" : uuidv4(),
            "contact_uuid": contact.contact_uuid,
            "ssas_vessel_uuid": ssas_vessel.ssas_vessel_uuid,
            "priority_level": priority,
            "active_email": false,
            "test_email": false,
            "active_screen": false,
            "test_screen": false,
            "deleted": null,
            "deleted_by": null
        }]
    }
    const result = await upsertSSASAffiliation(newAffiliation);
    if(result.is_successful){
        await logAffiliationEvents(contact, ssas_vessel, "NEW");
        return newAffiliation.SSASAffiliation_list[0];
    }
    else return null;
}

export async function upsertSSASAffiliation(linkDetails){
    console.log("linkDetails: ", linkDetails);
    const resSSASVessel = await post_data("/upsert_SSASAffiliation_List", linkDetails);
    return resSSASVessel;
}


export async function updateScheduledTestDetails(scheduledTest){
    const newTestParameters = {"test_list" : [{
      "schedule_uuid" : scheduledTest.schedule_uuid,
      "ssas_vessel_uuid" :scheduledTest.ssas_vessel_uuid,
      "user_uuid" : scheduledTest.user_uuid,
      "parent_uuid" : scheduledTest.parent_uuid,
      "schedule_date"  : scheduledTest.schedule_date,
      "schedule_by" : scheduledTest.schedule_by,
      "started_by" : scheduledTest.started_by,
      "started_date" : scheduledTest.started_date,
      "completed_date" : scheduledTest.completed_date,
      "completed_by" : scheduledTest.completed_by,
      "deleted" : scheduledTest.deleted,
      "deleted_by" : scheduledTest.deleted_by,
      "current_status" : scheduledTest.current_status,
      "test_active" : scheduledTest.test_active
    }]};

    const result = await upsertSSASSchedule(newTestParameters);
    return result;
}

export async function postSSASSchedule(newScheduledTests){
    const newTestParameters = {"test_list" : newScheduledTests}

    const result = await upsertSSASSchedule(newTestParameters);
    if(result.is_successful){
        return newTestParameters;
    }
    else{
        return null;
    }
}

export async function upsertSSASSchedule(schedule_test){
    const resScheduleTest = await post_data_mws("/upsertSSASScheduledTest", schedule_test, "SSAS_SCHEDULE");
    return resScheduleTest;
}

export async function createPostSSASEventLogParam(param_list: eventLogParameters[]){
    let event_list: SSASEvent[] = [];
    console.log("param_list: ", param_list);
    for (const event of param_list) {
        let event_log: SSASEvent = structuredClone(defaultSsasEvent);

        event_log.events_logs_uuid = uuidv4();
        event_log.user_uuid = session_user_uuid;
        event_log.parent_uuid = session_parent_uuid;

        if(event.alert != null) {
            let log_alert: SSASAlert = {};
            log_alert.alert_uuid = 'test_alert_uuid' in event.alert
                ? event.alert.test_alert_uuid
                : event.alert.alert_uuid;
            log_alert.vessel_info = event.alert.vessel_info;
            log_alert.start_date = event.alert.start_date;
        
            if (event.alert.end_date) log_alert.end_date = event.alert.end_date;

            event_log.alert_details = log_alert;
        }

        if(event.scheduledTests != null) event_log.test_details = event.scheduledTests;

        if(event.scheduledTests != null && event.vessel === null){
            if(event.scheduledTests.vessel_info != null && event.scheduledTests.vessel_info.ssas_vessel_uuid != null
            && event.scheduledTests.vessel_info.ssas_vessel_uuid != "") {
                event_log.ssas_vessel_details = event.scheduledTests.vessel_info;
            }
            else{
                const result = await getSSASVesselData([event.scheduledTests.ssas_vessel_uuid], [], "");
                if(result.length > 0) event_log.ssas_vessel_details = result[0];
            }
        }

        if(event.alert != null && event.vessel === null){
            if(event.alert.vessel_info != null && event.alert.vessel_info.ssas_vessel_uuid != null
            && event.alert.vessel_info.ssas_vessel_uuid != "") {
                event_log.ssas_vessel_details = event.alert.vessel_info;
            }
            else{
                const result = await getSSASVesselData([event.alert.ssas_vessel_uuid], [], "");
                if(result.length > 0) event_log.ssas_vessel_details = result[0];
            }
        }

        if(event.vessel != null) event_log.ssas_vessel_details = event.vessel;

        if(event.contact != null) event_log.contact_details = event.contact;
        console.log("event.contact: ", event.contact);
        event_log.log_date = G.setUTCDate();
        event_log.log_type = await ssasLogTypes.get(event.log_type_number)?.[0]
        event_log.log_mode = await logStatusElement.get(event.log_mode_number)?.[0];
        event_log.log_details = event.result_notes;

        if(param_list.completed_uuid != null && param_list.completed_uuid != ""){
            event_log.completed = G.setUTCDate();
            event_log.completed_by.user_uuid = param_list.completed_uuid;
        }

        event_list.push(event_log);
    };
    console.log("event_list: ", event_list);
    const response = await postSSASEventLog(event_list);
    return response;
}

export async function postSSASEventLog(ssasEventList: SSASEvent[]){
    let log_list = { "event_list" : ssasEventList };

    const result = await insertSSASEventLog(log_list);
    return result.is_successful;
}

async function insertSSASEventLog(parameters){
    const res = await post_data_mws("/insertSSASEventLog", parameters, "SSAS_EVENT");
    return res;
}

export async function logAffiliationEvents(addContact, ssasVessel, log_notes){
    let parameters: eventLogParameters = structuredClone(defaultLogParameters);
    parameters.log_type_number = 12;
    parameters.log_mode_number = 0;
    console.log("addContact: ", addContact);
    console.log("ssasVessel: ", ssasVessel);
    if(addContact) parameters.contact = addContact;
    if(ssasVessel) parameters.vessel = ssasVessel;

    if(log_notes === "NEW") parameters.result_notes = " New affiliation between contact "+parameters.contact.user_name+" and vessel "+ parameters.vessel?.vessel_name;
    else if(log_notes === "DELETE") parameters.result_notes = " Delete affiliation between contact "+parameters.contact.user_name+" and vessel "+ parameters.vessel?.vessel_name;
    else parameters.result_notes = log_notes;

    if(parameters.contact?.user_uuid === session_user_uuid) parameters.completed_uuid = parameters.completed_uuid;
    else{
        const editingUser = await getContactData([], [], true);
        parameters.completed_uuid = editingUser.contact_uuid;
    }
    console.log("parameters: ", parameters);
    await createPostSSASEventLogParam([parameters]);
}

export async function updateAlarmMute(affil_uuids, new_state, live_mute){
    const parameters = {
        edit_live_alarm: live_mute,
        new_state: new_state,
        SSASAffiliation_uuids: affil_uuids
    }
    const result = await post_data("/upsert_SSASAlarmMute", parameters);
    return result;
}

export async function DeleteLiveAlarm(alert_details){
    if(alert_details.end_date === null){
        alert_details.end_date = G.setUTCDate();
    }
    console.log("alert_details: ", alert_details);
    const result = await post_data("/delete_SSASAlertOrArchive", { alert: alert_details, achive_alert: true });
    return result;
}

export async function DeleteTestAlarm(alert_details){
    if(alert_details.end_date === null){
        alert_details.end_date = G.setUTCDate();
    }

    const result = await post_data("/delete_SSASTest", { test_alert: alert_details });
    return result;
}

function getNestedValue(obj, path) {
    return path.split('.').reduce((acc, key) => acc && acc[key], obj);
}

export function exportSSASTable<T>(data: T[], column: ColumnDef<T, any>[], type: string, breadcrumbs: string){
    let page_titles = breadcrumbs.split('|');
    let title = "SSAS "+G.addSpacesBeforeCaps(page_titles[0]);
    const header = column.map(col => {
        let header_text = "";
        if (typeof col.header === 'string') header_text = col.header.toUpperCase();
        else if (React.isValidElement(col.header)) header_text = col.header.props?.children.toUpperCase() || '';
        if(header_text === "DELETE" || header_text === "EXPAND" || header_text === "LOCATE") header_text = "";

        return header_text != "" ? header_text : null;
    }).filter(header_text => header_text !== null);

    const rows = data.map(row => {
            return column.reduce((acc, col) => {
                let header_text = "";
                if (typeof col.header === 'string') header_text = col.header;
                else if (React.isValidElement(col.header)) header_text = col.header.props?.children || '';

                if(header_text.toUpperCase() === "DELETE" || header_text.toUpperCase() === "EXPAND" ||
                    header_text.toUpperCase() === "LOCATE") delete acc[header_text];
                else{
                    console.log("value: ", getNestedValue(row, col.accessorKey));
                    acc[header_text] = row[col.accessorKey];
                    if(acc[header_text] === undefined) acc[header_text] = getNestedValue(row, col.accessorKey);

                    if (React.isValidElement(acc[header_text])) acc[header_text] = acc[header_text].props?.children || '';
                    else if(typeof acc[header_text] === 'object' && acc[header_text]?.user_name) acc[header_text] = acc[header_text].user_name;

                    if (acc[header_text] && header_text !== header_text.toUpperCase()) {
                        acc[header_text.toUpperCase()] = acc[header_text];
                        delete acc[header_text];
                    }
                }

                return acc;
            }, {});
        });

    if (type === "CSV") {
            const rowsCSV = rows.map(row => header.map(col => row[col]).join(',')).join('\n');
            G.exportToCSV(rowsCSV, header, title);
    }
    else{
        G.exportToExcel(rows, header, title);
    }
}