import React, { createContext, useState, useEffect, ReactNode } from 'react';

interface WebSocketMessage {
  topic: string;
  subtopic: string;
  data: any;
}

interface WebSocketContextType {
  message: WebSocketMessage | null;
  wsService: WebSocketService;
  subscribeToTopic: (topic: string, subtopic: string, data: any) => void;
}

interface WebSocketProviderProps {
  children: ReactNode; // Define the type of children
}

export class WebSocketService {
  public socket: WebSocket | null = null;
  private listeners: { [topic: string]: Array<(message: WebSocketMessage) => void> } = {};
  private url: string;
  private pongReceived:boolean;
  public connectionState: 'connecting' | 'open' | 'closed' = 'connecting';


  constructor(url: string) {
    this.url = url;
    this.socket = null;
    this.pongReceived = true;
  }

  connect(): Promise<void> {
    return new Promise((resolve, reject) => {
        if (this.socket) {
          //console.warn('WebSocket is already connected.');
          return;
        }

        this.socket = new WebSocket(this.url);

        this.socket.onopen = () => {
          //console.log('WebSocket connection established.');
          this.connectionState = 'open';
          resolve();
        };

        this.socket.onmessage = (event) => {
          if (event.data === "ping") {
              //console.log("Ping received from server, sending pong...");
              if (this.socket) this.socket.send("pong"); // Respond with a pong
              return;
          }
          else{
              const message = JSON.parse(event.data);
              console.log('Message received:', message);
              const listeners = this.listeners[message.topic];
              if (listeners) {
                listeners.forEach((listener) => listener(message)); // Pass typed message
              }
          }
        };

        this.socket.onclose = () => {
          //console.log('WebSocket connection closed.');
          this.connectionState = 'closed';
          this.reconnect();
        };

        this.socket.onerror = (error) => {
          console.error('WebSocket error:', error);
          this.connectionState = 'closed';
          //reject(error);
        };

    });
  }

  disconnect() {
    if (this.socket) this.socket.close();
  }

  reconnect() {
      //console.log("Reconnecting WebSocket...");
      setTimeout(() => this.connect(), 5000); // Reconnect after 5 seconds
  }

  sendMessage(message: any) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(message));
    } /*else {
      console.error('Cannot send message. WebSocket is not open.');
    }*/
  }

  addListener(topic: string, subtopic: string, listener: (message: WebSocketMessage) => void): void {
      // Ensure there's an array of listeners for this topic
      if (!this.listeners[topic]) {
        this.listeners[topic] = [];
      }
      this.listeners[topic].push(listener); // Add the listener for this topic
    }

    removeListener(topic: string, subtopic: string, listener: (message: WebSocketMessage) => void): void {
      const listeners = this.listeners[topic];
      if (listeners) this.listeners[topic] = listeners.filter((l) => l !== listener);
    }

    subscribeToTopic(topic: string, subtopic: string, data: any, listener: (message: WebSocketMessage) => void): void {
      if (!this.socket) {
        console.error('WebSocket is not connected.');
        return;
      }

      const subscribeMessage = { topic,  subtopic, data, action: 'subscribe' };
      this.sendMessage(JSON.stringify(subscribeMessage));
      this.addListener(topic, subtopic, listener);
    }

    unsubscribeFromTopic(topic: string, subtopic: string, data: any,  listener: (message: WebSocketMessage) => void): void {
      this.removeListener(topic, subtopic, listener);
      if (this.socket) {
        // Send unsubscribe request (the actual message structure depends on the server)
        const unsubscribeMessage = { topic, subtopic, data, action: 'unsubscribe' };
        this.sendMessage((JSON.stringify(unsubscribeMessage)));
      }
    }
}

const WebSocketContext = createContext<WebSocketContextType | null>(null);

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
  const [wsService] = useState(new WebSocketService('https://ws.maritrace.com'));
  const [message, setMessage] = useState<WebSocketMessage | null>(null);

  useEffect(() => {
      wsService.connect();

      /*return () => {
        wsService.socket?.close();
      };*/
    }, [wsService])

  const subscribeToTopic = (topic: string, subtopic: string, data: any) => {
    wsService.subscribeToTopic(topic, subtopic, data, (message) => {
      setMessage(message);
    });
  };

  return (
    <WebSocketContext.Provider value={{ message, wsService, subscribeToTopic }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export { WebSocketContext };
