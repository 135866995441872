import React from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { post_data } from '../API_Calls.js';
import { session_user_uuid, session_parent_uuid }from '../session.js';
import library from './FontAwesome_Setup';
import ModalHandler from '../../Components/Modal_Handler';
import { sendDataToParent,host_url, got_user_uuid } from '../ModuleFunctions/Iframe_Handler';
import * as XLSX from 'xlsx';

export var all_user_settings = [];

let get_uuid_counter = 0;

export async function getUserUuid(){
    if(host_url.toString().includes('localhost') && !host_url.toString().includes('pages/')){
        const url = new URL(window.location.href);
        const user_uuid = url.searchParams.get('user');
        const parent_uuid = url.searchParams.get('parent');
        if(user_uuid && user_uuid != '' && parent_uuid && parent_uuid != '') {
            await got_user_uuid(user_uuid, parent_uuid);
        }
        else{
            //await got_user_uuid('50952471-97B3-408E-A6E2-31CA6C747EF0', '55325587-9E4C-4DB8-92D0-CEF615264991'); //johnny boy demo account
            await got_user_uuid('ee370760-7e9c-4bcf-a667-a2937de4753d', '6ad06cfb-85aa-425d-bde6-eff63ac33211');
            //await got_user_uuid('4c48d87a-3739-4fa2-8338-1fbf021dea93', '6ad06cfb-85aa-425d-bde6-eff63ac33211');
        }
    }
    else{
        if(session_user_uuid === ""){
            sendDataToParent({ item: "parse_user_uuid" });

            if(get_uuid_counter < 30){
                setTimeout(function () {
                    get_uuid_counter ++;
                    getUserUuid();
                }, 2000);
            }
        }
        else{
            get_uuid_counter = 0;
        }
    }
}

export function formatStringForHTML(str: string): React.ReactNode {
  return str.split('\\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
}

export async function getUserSettings(user_uuid: string, field_name: string, is_ffid: boolean){
    var parameters = {};
    if(is_ffid){
        parameters = {
           ffid:user_uuid,
           setting_field_name: field_name
        };
    }else{
        parameters = {
           user_uuid:user_uuid,
           setting_field_name: field_name
        };
    }

    const res = await post_data("/get_IndividualUsersetting", parameters);

    try{
        const parsedValue = JSON.parse(res.setting_value);
        return parsedValue;
    } catch{
        return null;
    }
}

export function loadExternalScript(src, callback){
  const existing_script = document.querySelector(`script[src="${src}"]`);

  if (!existing_script) {
    const script = document.createElement('script');
    script.src = src;
    script.crossOrigin = 'anonymous';
    script.async = true;

    script.onload = () => {
      if (callback) callback(null, script);
    };

    script.onerror = () => {
      if (callback) callback(new Error(`Failed to load script: ${src}`));
    };

    document.body.appendChild(script);
  } else if (callback) {
    // Script is already present
    callback(null, existing_script);
  }
};

export function showLoadingPanel(){
    return <div className='element_loading_container'>
        <div className="icon_cont">
            <FontAwesomeIcon icon={['fass', 'arrows-rotate']} />
        </div>
    </div>
}

export function getUTCTimeDifference(UTCdate, date2){
    var comparison_UTCdate = new Date(UTCdate);
    var comparison_date2 = new Date(date2);
    var time_difference_ms = 0;

    const localTime = comparison_date2.getTime()
    const localOffset = comparison_date2.getTimezoneOffset() * 60 * 1000
    const utcTime = localTime + localOffset

    time_difference_ms = Math.abs(Math.floor(utcTime) - Math.floor(comparison_UTCdate.getTime()));
    var time_difference_minutes = Math.floor(time_difference_ms / (1000 * 60));
    return time_difference_minutes;
}

export function convertToTimeZone(date, tzString) {
    date = new Date(date);
    if(tzString == 'UTC'){
        var utc_date = date.toISOString();
        return new Date(utc_date);
    }
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));
}

export const exportToCSV = (data, columns, title) => {
  const header = columns.join(',') + '\n';
  const rows = data;

  const csvContent = 'data:text/csv;charset=utf-8,' + header + rows;
  const encodedUri = encodeURI(csvContent);

  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  const formattedDate = new Date().toISOString().split('T')[0];
  const file_name = title.replace(/ /g, "_") + "_" + formattedDate;

  link.setAttribute('download', file_name+'.csv');
  document.body.appendChild(link);

  link.click();
};

export const exportToExcel = (data, columns, title) => {
  const ws = XLSX.utils.json_to_sheet(data, {
    header: columns,
  });
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  const formattedDate = new Date().toISOString().split('T')[0];
  const file_name = title.replace(/ /g, "_") + "_" + formattedDate;
  XLSX.writeFile(wb, file_name+'.xlsx');
};

export function centerTableHeaderfilters(){
    document.querySelectorAll('.custom-table_cont th').forEach(th => {
      const hasCenterHeader = th.querySelector('.center_header');
      const inputElement = th.querySelector('input');

      if (hasCenterHeader && inputElement) {
        inputElement.classList.add('custom-class');
      }
    });
}

export function isNumberOnly(str) {
   return /^\d+$/.test(str);
}

export function endStringByLastOfChar(s: string, char: string): string {
    const index = s.lastIndexOf(char);

    if (index !== -1) return s.slice(0, index);
    else return s;
}

export function getEmptyUUID() {
    return "00000000-0000-0000-0000-000000000000";
}

export function setUTCDate(inputDate = new Date()) {
   try{
       return new Date(Date.UTC(
           inputDate.getUTCFullYear(),
           inputDate.getUTCMonth(),
           inputDate.getUTCDate(),
           inputDate.getUTCHours(),
           inputDate.getUTCMinutes(),
           inputDate.getUTCSeconds(),
           inputDate.getUTCMilliseconds()
       ));
   }
   catch {
       return inputDate;
   }
}

export function addSpacesBeforeCaps(str) {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2');
}

export function isBlankUUID(uuid) {
   const blankUUID = "00000000-0000-0000-0000-000000000000";
   return uuid === blankUUID;
}