import React, { useContext, useEffect, useState, useRef } from 'react';
import { WebSocketContext } from './WebSocket';

type MessageContent = { topic: string, subtopic: string, data: any };

const WSMessageHandler = (() => {
  const globalRegistry: Map<string, Set<string>> = new Map();
  const messageCache: MessageContent[] = [];
  const listeners: Set<(messages: MessageContent[]) => void> = new Set();

  const ensureSubscription = (topic: string, subtopic: string) => {
    if (!globalRegistry.has(topic)) globalRegistry.set(topic, new Set());

    const subtopics = globalRegistry.get(topic)!;
    if (!subtopics.has(subtopic)) {
      subtopics.add(subtopic);
      return true;
    }
    return false;
  };

  const notifyListeners = () => {
    const messages = [...messageCache];
    listeners.forEach((listener) => listener(messages));
  };

  const addMessage = (newMessage: MessageContent) => {
    const existingIndex = messageCache.findIndex(
      (msg) => msg.topic === newMessage.topic && msg.subtopic === newMessage.subtopic
    );

    if (existingIndex >= 0) messageCache[existingIndex] = newMessage;
    else messageCache.push(newMessage);

    notifyListeners();
  };

  return {
    useMessages(subscriptions: MessageContent[]) {
      const context = useContext(WebSocketContext);
      if (!context) throw new Error('WebSocket context is not available!');

      const { message, wsService, subscribeToTopic } = context;
      const [localMessages, setLocalMessages] = useState<MessageContent[]>([]);
      const subscriptionsRef = useRef(subscriptions);
      subscriptionsRef.current = subscriptions;

      useEffect(() => {
        if (wsService.connectionState === 'open') {
          subscriptions.forEach(({ topic, subtopic, data }) => {
            const needsSubscription = ensureSubscription(topic, subtopic);
            if (needsSubscription) {
              console.log(`Subscribing to ${topic}:${subtopic}`);
              subscribeToTopic(topic, subtopic, data);
            }
          });
        }
      }, [wsService.connectionState, subscribeToTopic]);

      useEffect(() => {
        if (message && message.topic && message.subtopic) addMessage(message);
      }, [message]);

      useEffect(() => {
        const updateLocalMessages = (allMessages: MessageContent[]) => {
          const filteredMessages = allMessages.filter((msg) =>
            subscriptionsRef.current.some((sub) => sub.topic === msg.topic && sub.subtopic === msg.subtopic)
          );
          setLocalMessages(filteredMessages);
        };

        listeners.add(updateLocalMessages);
        updateLocalMessages(messageCache); // Sync immediately with cache
        return () => {
          listeners.delete(updateLocalMessages);
        };
      }, [subscriptionsRef]); // Only react to changes in subscriptions

      return localMessages;
    },
  };
})();

export default WSMessageHandler;
